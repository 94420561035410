import './App.css';

const TILE_OPACITY = 0.3;

// Initialize leaflet.js
var L = require('leaflet');

// Initialize the map
var map = L.map('map', {
	scrollWheelZoom: true
});

// Initialize the base layer
var osm_mapnik = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
	maxZoom: 19,
	attribution: '&copy; OSM Mapnik <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
	className: 'map-tiles'
}).addTo(map);


var maxNumPolicies = 0
var jsonObject = null
var max_lat = 0
var min_lat = 0
var max_lon = 0
var min_lon = 0

SetMapZoom()
GetTilesRepeat()

function SetMapZoom() {
	// Set the position and zoom level of the map
	map.setView([38, -97], 5);

	// being able to view the zoom value of the map
	var ZoomViewer = L.Control.extend({
		onAdd: function () {
			var gauge = L.DomUtil.create('div');
			gauge.style.width = '200px';
			gauge.style.background = 'rgba(255,255,255,0.5)';
			gauge.style.textAlign = 'left';
			map.on('zoomstart zoom zoomend', function (ev) {
				gauge.innerHTML = 'Zoom level: ' + map.getZoom();
			});
			return gauge;
		}
	});

	var zoomViewer = (new ZoomViewer()).addTo(map);
	
	//calls GetTiles every time map zooms
	map.on('zoomend', ClearTiles)
	
	map.on('moveend', ClearTiles)
}

function ClearTiles() {
    //clear the screen of any old polygons and then shows the new ones
    //map.clearLayers()

    map.eachLayer(function (layer) {
        map.removeLayer(layer);
    });

	//console.log(map.getBounds().getNorthEast().lat)

    var osm_mapnik = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; OSM Mapnik <a href="http://www.openstreetmap.org/copyright%22%3EOpenStreetMap</a>',
        className: 'map-tiles'
    }).addTo(map);

    ShowTiles()
}

//calls GetTiles() repeatedly
function GetTilesRepeat() {
	
	ClearTiles()

	setTimeout(GetTilesRepeat, 1000*60*60) //refreshes every hour
	//setTimeout(GetTilesRepeat, 1000*2) //refreshes every hour
}

//calls the query to get the tiles, runs repeatedly 
async function GetTiles() {
	var zoomLevel = map.getZoom()
	var resolution = 0
	if (zoomLevel < 6) {
		resolution = zoomLevel - 3
	} else if (zoomLevel == 7 || zoomLevel == 8) {
		resolution = 4
	} else if (zoomLevel == 6) {
		resolution = 2
	} else {
		resolution = zoomLevel - 5
	}
	
	if (resolution > 6) {
		resolution = 6
	} else if (resolution < 0) {
		resolution = 0
	}

	jsonObject = await getAllH3Tile(resolution);
}

// Determines if tile is on screen
function withinBounds(tile) {
	max_lat = map.getBounds().getNorthEast().lat;
	min_lat = map.getBounds().getSouthWest().lat;
	max_lon = map.getBounds().getNorthEast().lng;
	min_lon = map.getBounds().getSouthWest().lng;
	if (tile.CENTROID_LAT < max_lat && tile.CENTROID_LAT > min_lat && tile.CONTROID_LON < max_lon && tile.CONTROID_LON > min_lon) {
		return true;
	}
	return false;
}

//uses the max and min values to create a color gradient
function SetColorScheme() {
	maxNumPolicies = 0;
	const tiles = jsonObject.data.getH3TileByResolution;

	tiles.forEach(tile => {
		if (tile.NUM_POLICIES != null) {
			if (tile.NUM_POLICIES > maxNumPolicies && withinBounds(tile)) {
				maxNumPolicies = tile.NUM_POLICIES
			}
		}
	});

}

//creates the tiles and displays them on the map
async function ShowTiles() {
	
	await GetTiles()
	SetColorScheme()
	const tiles = jsonObject.data.getH3TileByResolution;

	tiles.forEach(tile => {

		const coordArray = [];

		for (var i = 1; i < 13; i++) {
			const lat = eval("tile.P" + i + "_LAT")
			const lon = eval("tile.P" + i + "_LON")
			
			if ( lat != null && lon != null ){
				coordArray.push([lat, lon])
			}
		}

		if (tile.NUM_POLICIES != null) {

			var polygon = L.polygon(coordArray, {
				fillColor: SetColor(tile.NUM_POLICIES),
				opacity: 0.0, //stroke opacity
				fillOpacity: TILE_OPACITY,
				//onEachFeature: onEachFeature
			}).addTo(map)

			polygon.on('click', async function () {
				document.getElementById("latitude").innerHTML = "Latitude: " + this.getCenter().lat;
				document.getElementById("longitude").innerHTML = "Longitude: " + this.getCenter().lng;
				document.getElementById("policynum").innerHTML = "Number of Policies: " + tile.NUM_POLICIES;
			});

			polygon.on('mouseout', function () {

				polygon.setStyle({
					fillColor: SetColor(tile.NUM_POLICIES),
					opacity: 0.0, //stroke opacity
					fillOpacity: TILE_OPACITY,
				});

			});
			polygon.on('mouseover', function () {

				polygon.setStyle({
					weight: 5,
					fillColor: 'orange',
					dashArray: '',
					fillOpacity: TILE_OPACITY,
					opacity: 1,
					color: 'white',
					dashArray: '3'
				});
				if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
					polygon.bringToFront();
				}
			});

		}

	});

}

//creates a color for the tile depending on the number of policies
function SetColor(numPolicies) {
	const zeroPad = (num, places) => String(num).padStart(places, '0') // formats the hex colors

	var fraction = 1.0 * numPolicies / maxNumPolicies;

	var red = parseInt(255 - (fraction * 255));
	var green = parseInt(255 - (fraction * 255));
	var blue = 255

	return '#' + zeroPad(red.toString(16), 2) + zeroPad(green.toString(16), 2) + zeroPad(blue.toString(16), 2);
}

// AppSync query that returns all H3 tiles of a certain resolution
async function getAllH3Tile(resolution) {
	// UPDATE TO /GRAPHQL WHEN DEPLOYING
  let response = await fetch("https://policydensity.pieinsurance.com/graphql", {
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    query: `
	query MyQuery ($resolution: Int!) { 
    getH3TileByResolution(resolution: $resolution) {
	  tileHash
      resolution
      CENTROID_LAT
      CONTROID_LON
      NUM_POLICIES
      P1_LAT
	  P1_LON
	  P2_LAT
	  P2_LON
	  P3_LAT
	  P3_LON
	  P4_LAT
	  P4_LON
	  P5_LAT
	  P5_LON
	  P6_LAT
	  P6_LON
	  P7_LAT
	  P7_LON
	  P8_LAT
	  P8_LON
	  P9_LAT
	  P9_LON
	  P10_LAT
	  P10_LON
	  P11_LAT
	  P11_LON
	  P12_LAT
	  P12_LON
    } 
  }`, variables: {resolution}
  })
})
  .then((r) => r.json())
  .then((data) => {
    console.log(data)
    return data;
  }).catch((error) => console.log(error));
  return response;
}

function App() {
	return (
		<div className="Pie Insurance App">
		</div>
	);
}

export default App;
